<template>
  <content-layout :breadcrumbs-items="breadcrumbsItems" :fluid="true">
    <validation-observer
      ref="observer"
      v-slot="{ invalid }"
      tag="div"
      rounded="lg"
    >
      <v-card :max-width="700" class="my-10" flat>
        <v-card-text>
          <v-form
            @submit.prevent="saveAccountSettings"
            v-if="accountCopy"
            :disabled="loading"
          >
            <div class="d-flex align-center" style="gap: 15px">
              <cz-form-field
                :label="$t('customer.companyLogo')"
                class="flex-grow-1"
              >
                <v-input class="full-width">
                  <cz-dropzone
                    accept="image/*"
                    @change="onLogoChanged"
                    width="100%"
                    :height="120"
                    :title="$t('dropzone.title')"
                    :disabled="loading"
                  >
                    <div slot="files" class="py-2 dropzone-files">
                      <cz-chip
                        v-if="selectedLogoFile"
                        :text="selectedLogoFile.name"
                        dark
                        color="secondary"
                        class="text-no-wrap text-truncate"
                        circle-edges
                        removeable
                        small
                        @remove="onSelectedLogoRemoved"
                      />
                    </div>
                  </cz-dropzone>
                </v-input>
              </cz-form-field>
              <v-img
                :src="logo"
                :height="80"
                :width="80"
                :max-height="80"
                :max-width="80"
                class="mt-3"
              />
            </div>
            <cz-form-field :label="$t('customer.systemTags')">
              <template #label>
                <div class="d-flex align-center" style="column-gap: 10px">
                  <div>{{ $t('customer.systemTags') }}</div>
                  <cz-menu-button
                    :button-icon-src="mdiInformation"
                    flat
                    color="primary"
                    :text="$t('customer.systemTagsInfo')"
                    :menu-max-width="250"
                  />
                </div>
              </template>
              <cz-input-tags
                v-model="accountCopy.systemTags"
                :hint="$t('customer.systemTagsHint')"
                persistent-hint
                allow-enter-new-values
              />
            </cz-form-field>
            <div class="d-flex mt-5">
              <cz-button
                :title="$t('common.saveChanges')"
                color="primary"
                type="submit"
                :disabled="!formChanged"
                :loading="loading"
              />
            </div>
          </v-form>
        </v-card-text>
      </v-card>
    </validation-observer>
  </content-layout>
</template>

<script>
import ContentLayout from '@/layouts/ContentLayout.vue';
import { mapFields } from 'vuex-map-fields';
import cloneDeep from 'lodash.clonedeep';
import logo from '@/assets/corz.png';
import isEqual from 'lodash.isequal';
import { mdiInformation } from '@mdi/js';
import {
  CzFormField,
  CzInput,
  CzButton,
  CzDropzone,
  CzInputTags,
  CzIcon,
  CzMenuButton,
  CzChip
} from '@/components';
import {
  fileReaderPromise,
  ReaderType
} from '@/shared/services/fileReader.service';
import { updateAccount, uploadFiles } from '@/core/api';
export default {
  name: 'AccountCustomizationPage',
  components: {
    ContentLayout,
    CzFormField,
    CzInput,
    CzButton,
    CzChip,
    CzDropzone,
    CzIcon,
    CzMenuButton,
    CzInputTags
  },
  computed: {
    ...mapFields('auth', ['account']),
    breadcrumbsItems() {
      return [
        {
          text: this.$t('common.homepage'),
          to: {
            name: 'customer'
          },
          disabled: false,
          exact: true,
          isHome: true
        },
        {
          text: this.$t('customer.customization'),
          disabled: true,
          exact: true
        }
      ];
    },
    logo() {
      if (this.selectedLogoSrc) {
        return this.selectedLogoSrc;
      } else if (!this.accountCopy.logo) {
        return logo;
      } else {
        return this.accountCopy.logo.url;
      }
    },
    formChanged() {
      return this.selectedLogoFile || !isEqual(this.account, this.accountCopy);
    }
  },
  created() {
    this.accountCopy = cloneDeep(this.account);
  },
  data() {
    return {
      accountCopy: null,
      selectedLogoFile: null,
      selectedLogoSrc: null,
      loading: false,
      mdiInformation
    };
  },
  methods: {
    async saveAccountSettings() {
      try {
        this.loading = true;
        if (this.selectedLogoFile) {
          const [logoFile] = await uploadFiles(
            [this.selectedLogoFile],
            this.account._id
          );

          this.accountCopy.logoFileId = logoFile._id;
        }

        const account = await updateAccount(this.account._id, this.accountCopy);
        this.account = cloneDeep(account);
        this.accountCopy = cloneDeep(account);
        this.selectedLogoFile = null;
      } finally {
        this.loading = false;
      }
    },
    async onLogoChanged(files) {
      this.selectedLogoFile = files[0];
    },
    onSelectedLogoRemoved() {
      this.selectedLogoFile = null;
      this.selectedLogoSrc = null;
    }
  },
  watch: {
    selectedLogoFile: {
      async handler(value) {
        if (value) {
          this.selectedLogoSrc = await fileReaderPromise(value, ReaderType.URL);
        } else {
          a;
          this.selectedLogoSrc = null;
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.account-form {
  width: 400px !important;
  gap: 10px;
}
.dropzone-files {
  max-width: 200px;
}
</style>
